var map = undefined;
$(window).on('load', function(){
	var infoBar = $('#infoBar');
	var mainHeader = $('#main-header');
	if (infoBar.length > 0 && mainHeader.length > 0) {
		setTimeout(function(){
			mainHeader.addClass('opened-info-bar');
		}, 3000);

		$('[data-purpose="info-bar-close"]').click(function(){
			$.ajax({
				url:ajax_controller+'closeInfoBar',
				dataType:'json',
				type:'post'
			});

			mainHeader.removeClass('opened-info-bar');
		});
	}
});

$(document).ready(function() {
	/* MOBILE */

	//create js hook links

	$('.mailtolink').click(function(e) {
		handleMailtoLink($(this));
	});

	$('[data-purpose="home-form-button"]').click(function(e){
		var checked = $.find('.tomb-type-radio:checked');
		if (checked.length > 0) {
			var href = $(this).attr('href');
			href += '?tombType='+$(checked).val();
			
			document.location = href;
			e.preventDefault();
			
		} else {
			e.preventDefault();
		}
	});


	$('[data-purpose="remodal-open"]').click(function(){
		var target = $(this).data('popup-target');
		if ($(this).data('popup-target')) {
			$('[data-remodal-id="' + $(this).data('popup-target') + '"]').remodal().open();
		}
	});

	$('.home .radio-group input[type="radio"]').change(function(){
		var parent = $(this).parents('.radio-group');
		if (parent.length > 0) {
			var row = parent.parents('.form-content');
			if (!parent.hasClass('selected')) {
				var type = parent.data('type');
				if (type) {
					$('.radio-group[data-type="'+type+'"]').removeClass('selected');
					parent.addClass('selected');
				}
			}

			if (row.length > 0) {
				var url = row.data('form-url');
				document.location = url+'?tombType='+$(this).val();
			}
		}
	});

	if( $('.cookie-alert-outer').length > 0 ) {
		setTimeout(function(){
			var height = $('.cookie-alert-outer .content-width').outerHeight();
			$('.cookie-alert-outer').animate({'height':height+'px'}, 600);
		}, 550);

		var alertResize = debounce(function(){
			if(!$('.cookie-alert-outer').hasClass('closed')){
				var height = $('.cookie-alert-outer .content-width').outerHeight();
				$('.cookie-alert-outer').height(height);
			}
		}, 50);

		window.addEventListener('resize', alertResize);

		$('.close-cookie-alert').click(function(){
			$('.cookie-alert-outer').animate({'height':'0px'}, 600);
			$('.cookie-alert-outer').addClass('closed');

			$.ajax({
				url:ajax_controller+'setCookieConfirm',
				dataType:'json',
				type:'post',
				data:{cookie:1},
				success: function(){}
			});
		});
	}

	$('[data-purpose="remodal-close"]').click(function(){
		var value = $(this).data('value');
		if (value && value == 'confirm') {
			$('#terms-chk').attr('checked', true);
		}

		$(this).parents('.remodal').remodal().close();
	});

	$('[data-purpose="scroll-to-element"]').click(function(){
		var target = $(this).data('target');
		var item = $(target);
		
		if (item.length > 0) {
			var speed = $(this).data('speed');
			if (!speed) {
				speed = 400;
			}

			$('html, body').animate({scrollTop: item.offset().top}, speed);
		}
	});

	$('[data-purpose="question-opener"]').click(function(){
		var article = $(this).parents('article');
		
		if (article.length == 1) {
			var desc = article.find('.description');
			if (desc.length > 0) {
				
				if (desc.hasClass('opened')) {
					desc.removeAttr('style');
					desc.removeClass('opened');
					article.find('[data-purpose="question-opener"]').removeClass('opened');
					article.find('[data-purpose="question-opener"] .close-text').hide();
					article.find('[data-purpose="question-opener"] .detail-text').show();
				} else {
					var inner = desc.find('.inner');
					var height = inner.outerHeight();
					
					desc.addClass('opened');
					desc.height(height);
					article.find('[data-purpose="question-opener"]').addClass('opened');
					article.find('[data-purpose="question-opener"] .close-text').show();
					article.find('[data-purpose="question-opener"] .detail-text').hide();
				}
			}
		}
	});

	if (typeof site_id !== 'undefined' && site_id == 1) {
		var header = $('#main-header');
		var borderSection = $('#tombChooseSection');

		if (header.length > 0 && borderSection.length > 0) {
			var scrollFn = debounce(function() {
				var scrollTop = $(window).scrollTop();
				var top = borderSection.offset().top;
				if (scrollTop >= top && !header.hasClass('opened-fix-menu')) {
					header.addClass('opened-fix-menu');
				} else if (scrollTop <= top && header.hasClass('opened-fix-menu')) {
					header.removeClass('opened-fix-menu');
				}
			}, 40);

			window.addEventListener('scroll', scrollFn);
		}
	}

	$('.accordion-list').each(function(ind, el) {
		var options = {};
		var th = $(el);

		if (th.data('single-active-item') != undefined) options.singleActiveItem = true;
		if (th.data('active-index') != undefined) options.activeIndex = th.data('active-index');
		if (th.data('from-width') != undefined) options.fromWidth = parseInt(th.data('from-width'));
		
		new Accordion(th, options);
  	});
});

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function sendAnalyticsEvent(action, category, tag){
	if(typeof ga != 'undefined' && action && category){
		if(tag && tag.trim() != ''){
			ga('send', 'event', action, category, tag);
		}else{
			ga('send', 'event', action, category);
		}
	}
}


function svg(id) {
	return '<span class="svg"><svg class="icon ' + id + '"><use xlink:href="#' + id + '" /></svg></span>';
}


function __createFormInputs() {
	$('.mdl-js-textfield').each(function(ind, el) {
		var th = $(el);
		var input = th.children();
		var label = th.data('label');
		if (label == undefined) {
			label = input.attr('placeholder');
			input.attr('placeholder', null);
		}
		var id = input.attr('id');
		if (id == undefined) {
			id = input.attr('name');
			input.attr('id', id);
		}
		var classes = 'mdl-textfield';
		th.addClass(classes);
		input.addClass('mdl-textfield__input');

		if (label != undefined) {
			th.addClass('mdl-textfield--floating-label');
			th.append('<label class="mdl-textfield__label" for="' + id + '">' + label + '</label>');
		}

	});

	$('.mdl-checkbox').each(function(ind, el) {
		var th = $(el);
		var input = th.children();
		var label = th.data('label');
		if (label == undefined) {
			label = input.attr('value');
		}
		var id = input.attr('id');
		if (id == undefined) {
			id = input.attr('name') + '_' + ind;
			input.attr('id', id);
		}
		th.attr('for', id);
		var classes = 'mdl-js-checkbox mdl-js-ripple-effect';
		th.addClass(classes);
		input.addClass('mdl-checkbox__input');

		th.append('<span class="mdl-checkbox__label">' + label + '</span>');

	});

	$('.mdl-radio').each(function(ind, el) {
		var th = $(el);
		var input = th.children();
		var label = th.data('label');
		var error = th.data('error');
		if (label == undefined) {
			label = input.attr('value');
		}
		var id = input.attr('id');
		if (id == undefined) {
			id = input.attr('name') + '_' + ind;
			input.attr('id', id);
		}
		th.attr('for', id);
		var classes = 'mdl-js-radio mdl-js-ripple-effect';
		th.addClass(classes);
		input.addClass('mdl-radio__button');

		th.append('<span class="mdl-radio__label">' + label + '</span>');

		if (error) {
			th.append('<span class="mdl-textfield__error">' + error + '</span>');
		}

	});

	$('.custom-select:not(".with-button")').each(function(ind, el) {
		var th = $(el);
		var options = th.children('select');
		var id = options.attr('name');
		var label = th.children('.mdl-js-textfield');
		var defaultValue = '';

		var ul = th.children('ul');
		options.children().each(function(ind2, el2) {
			var th2 = $(el2);
			if (!ind2) defaultValue = th2.text();
			ul.append('<li class="mdl-menu__item" data-value="' + th2.attr('value') + '">' + th2.text() + '</li>');
		});
		ul.attr('for', id).addClass('mdl-menu--bottom-left mdl-js-menu mdl-js-ripple-effect');

		label.click(function(e) {
			e.preventDefault();
			e.stopPropagation();
			label.addClass('is-dirty');
			th.toggleClass('active');
			th.find('.mdl-menu__container').toggleClass('is-visible');
		});

		th.find('li').click(function() {
			var sel = $(this).text();
			label.removeClass('is-invalid').children('input').val(sel);
			options.val(sel);
			if (!options.val()) label.removeClass('is-dirty');
		});

		label.find('input').val(defaultValue);

	});

	$('.custom-select.with-button').each(function(ind, el) {
		var th = $(el);
		var options = th.children('select');
		var id = options.attr('name');
		var label = th.data('label');
		var url = th.data('url');

		var ul = th.children('ul');
		options.children().each(function(ind2, el2) {
			var th2 = $(el2);
			ul.append('<li class="mdl-menu__item" data-value="' + th2.attr('value') + '">' + th2.text() + '</li>');
		});
		ul.attr('for', id).addClass('mdl-menu--bottom-left mdl-js-menu mdl-js-ripple-effect');
		th.append('<a class="button orange-button" href="javascript:void(0)" id="' + id + '">' + label + '<span class="sprite mainSprite-whiteSelectArrow"></span></a>');

		th.find('.button').click(function(e) {
			e.preventDefault();
			e.stopPropagation();
			$(this).toggleClass('clicked');
		});

		th.find('li').click(function() {
			var sel = $(this).data('value');
			options.val(sel);

			if (url != undefined) {
				window.location = url + sel;
			}
		});

	});

	refreshInputs();

	
}

function refreshInputs() {
	componentHandler.upgradeAllRegistered();
}

function handleMailtoLink(th) {
	if (th.attr('href') == 'javascript:void(0)') {
		var hidden = th.children('.emailhidden');
		th.attr('href', 'mailto:' + hidden.data('user') + '@' + hidden.data('domain'));
		e.preventDefault();
	}
}

function googleMapsLoaded()
{
	var options = {
		zoom: 15,
		scrollwheel: false,
		mapTypeControl: false,
		scaleControl: false,
		fullscreenControl: false,
		streetViewControl: false,
		zoomControl: false,
		styles: getMapStyle()
	};

	var center = new google.maps.LatLng(47.5532027,21.6494543);
	options.center = center;

	map = new google.maps.Map(document.getElementById('googleMapsContent'), options);
	var infoWindow = new google.maps.InfoWindow();
	
	$('.google-maps-outer').addClass('loaded');
	loadJs(base_url + 'assets/js/infoBox.js', function() {
		setInfoBox();
	});
}

function loadJs(url, implementationCode){
    var scriptTag = document.createElement('script');
    scriptTag.src = url;

    scriptTag.onload = implementationCode;
    scriptTag.onreadystatechange = implementationCode;

    document.body.appendChild(scriptTag);
};

function setInfoBox() {
	var center = new google.maps.LatLng(47.5539126,21.6449536);
	var infoWindow = new InfoBox({
		latlng: center,
		content:'<div class="info-box-container">Benczúr Gyula u. 6.</div>',
		map: map,
		width:180
	});
}



function getMapStyle()
{
	return [
		{
			"featureType": "administrative.country",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#d1d1d1"
				},
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "administrative.province",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#ff0000"
				},
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "administrative.locality",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#ff0000"
				},
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "administrative.locality",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#d7c384"
				}
			]
		},
		{
			"featureType": "administrative.neighborhood",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#ff0000"
				},
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "administrative.land_parcel",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#ff0000"
				},
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "administrative.land_parcel",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#ff0000"
				},
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "landscape",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "on"
				}
			]
		},
		{
			"featureType": "landscape.man_made",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#ffffff"
				}
			]
		},
		{
			"featureType": "landscape.natural",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#ffffff"
				}
			]
		},
		{
			"featureType": "landscape.natural.landcover",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#ffffff"
				}
			]
		},
		{
			"featureType": "landscape.natural.terrain",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#ffffff"
				}
			]
		},
		{
			"featureType": "poi",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "on"
				}
			]
		},
		{
			"featureType": "poi",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#efefef"
				}
			]
		},
		{
			"featureType": "poi",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#ff0000"
				},
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "poi.attraction",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "poi.business",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "poi.government",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "poi.park",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#bee49c"
				},
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "poi.park",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#808080"
				}
			]
		},
		{
			"featureType": "poi.place_of_worship",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "poi.school",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "poi.sports_complex",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#e3e3e3"
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#a3a3a3"
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#d7c384"
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "geometry.stroke",
			"stylers": [
				{
					"color": "#d7c384"
				}
			]
		},
		{
			"featureType": "road.highway.controlled_access",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#c9c9c9"
				},
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "road.highway.controlled_access",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#ff0000"
				},
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "road.local",
			"elementType": "geometry",
			"stylers": [
				{
					"visibility": "simplified"
				}
			]
		},
		{
			"featureType": "road.local",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#dedede"
				}
			]
		},
		{
			"featureType": "transit",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#8edee8"
				}
			]
		}
	];
}
