(function(wnd){
    function ReferenceGallery (container, params) {
        try {
            if (!container) {
                throw 'Container is required!';
            }

            this.container = container;

            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (e) {
            console.error(e);
        }
    }

    ReferenceGallery.prototype = {
        container: undefined,
        selectedTags: [],
        medias: [],
        sendedForm: false,
        texts: {},
        photoswipeContainer: undefined,
        init: function() {
            this.setElements();
            this.loadItems();
            this.addDOMEvents();
        },
        setElements: function() {
            this.photoswipeContainer = document.querySelectorAll('.pswp')[0];
        },
        addDOMEvents: function() {
            var _self = this;
            $('[data-purpose="filter-btn"]').click(function(){
                var id = $(this).data('value');
                if (id == 'all') {
                    _self.resetFilter();
                } else {
                    if ($(this).hasClass('selected')) {
                        var index = _self.selectedTags.indexOf(id);
                        if (index >= 0) {
                            _self.selectedTags.splice(index, 1);
                        }
                        $(this).removeClass('selected');
                    } else {
                        if (_self.selectedTags.indexOf(id) < 0) {
                            _self.selectedTags.push(id);
                        }
                        $(this).addClass('selected');
                    }
                }

                _self.loadItems();
            });
        },
        addClickEvents: function() {
            var _self = this;
            
            this.container.find('[data-purpose="gallery-item"]').click(function(){
                _self.openGallery($(this).data('index'));
            });
        },
        resetFilter: function() {
            this.selectedTags = [];
            $('[data-purpose="filter-btn"]').removeClass('selected');
        },
        setParams: function(params) {
            if (params.texts) this.setTexts(params.texts);
        },
        loadItems: function() {
            var _self = this;

            $.ajax({
                url:base_url+'ajax/get_gallery_items',
                dataType:'json',
                type:'post',
                data: {
                    tags: _self.selectedTags
                },
                success: function(response) {
                    if (typeof response.medias !== 'undefined') {
                        _self.setMedias(response.medias);
                    }

                    _self.draw();
                }
            });
        },
        setMedias: function(medias) {
            this.medias = medias;
        },
        draw: function() {
            var inner = $('<div>', {
                class:'filtered-image-content'
            });
            
            if (this.medias.length > 0) {
                for (var i = 0; i < this.medias.length; i++) {
                    var media = this.medias[i];
                    
                    var item = $('<a>', {
                        class:'image-outer',
                        href:'javascript:void(0)',
                        'data-purpose': 'gallery-item',
                        'data-index': i
                    });
                    
                    item.append($('<span>', {
                        class:'image-container',
                        style:'background-image: url("'+media.msrc+'");'
                    }));

                    if (typeof media.title !== 'undefined' && media.title.trim() !== '') {
                        var txt = media.title.length > 200 ? media.title.substr(0, 197)+'...' : media.title;
                        item.append('<span class="note-content"><span>'+txt+'</span></span>');
                    }

                    inner.append(item);
                }

                
            } else {
                inner.append('<div class="no-result">'+this.getText('no_result')+'</div>');
            }

            this.container.html('');
            this.container.append(inner);
            this.addClickEvents();
        },
        openGallery: function(index) {
            if (!index) {
                index = 0;
            }
            
            var options = {
                index: index,
                history: false
            };
            
            var gallery = new PhotoSwipe(this.photoswipeContainer, PhotoSwipeUI_Default, this.medias, options);
            gallery.init();
        
            gallery.options.history = false;
        },
        setTexts: function(texts) {
            this.texts = $.extend({}, this.texts, texts);
        },
        getText: function(key) {
            return typeof this.texts[key] !== 'undefined' ? this.texts[key] : key;
        }
    };

    wnd.ReferenceGallery = ReferenceGallery;
})(window);
