(function (wnd) {
    function TombFormItem(key, params, parent) {
        try {
            this.key = key;

            if (parent) {
                this.parent = parent;
            }
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (e) {
            console.error(e);
        }
    }

    TombFormItem.prototype = {
        key: undefined,
        parent: undefined,
        required: false,
        two_step_check: false,
        lead: undefined,
        values: undefined,
        title: undefined,
        subtitle: undefined,
        input_name: undefined,
        input_type: 'radio',
        content: undefined,
        secondary_content: undefined,
        swipe_content: undefined,
        step: undefined,
        available: false,
        defaultHeight: 0,
        duringInit: true,
        currentActiveSwipe: undefined,
        init: function () {
            this.setHtml();
            this.draw();

            // this.setRowWidth();
            this.addDOMEvents();

            this.setRowWidth();

            this.duringInit = false;
        },
        addDOMEvents: function () {
            var _self = this;
            if (this.getKey() == 'tomb-type' && this.parent.getSelectedTombType()) {
                var selected = this.content.find('input[type="radio"]:checked');
                if (selected.length > 0) {
                    selected.parents('.radio-group').addClass('selected');
                }
            }

            this.content.find('.main-row input[type="radio"]').change(function () {
                if (!_self.isTwoStepCheck() || (_self.isTwoStepCheck() &&  !_self.secondary_content)) {
                    _self.hideSwipeContent(400, _self.currentActiveSwipe);
                    _self.parent.openNextSteps(_self.step);
                }

                if (_self.getKey() == 'tomb-type') {
                    var parent = $(this).parents('.radio-group');
                    if (parent.length > 0) {
                        if (!parent.hasClass('selected')) {
                            _self.content.find('.radio-group').removeClass('selected');
                            parent.addClass('selected');
                        }
                    }
                }

                if (_self.getKey() == 'stones') {
                    $(document).trigger('sirkoform.changestone', {
                        stone: $(this).val()
                    });
                }


            });

            if (this.getKey() == 'extend-type') {
                var modifiable = ["granite", "marble", "terrazzo"]
                $(document).on('sirkoform.changestone', function (e, data) {
                    if (data !== undefined && typeof data.stone !== 'undefined') {
                        var stone = data.stone;
                        var secondContent = _self.secondary_content.find('.second-step[data-parent="' + stone + '"]');
                        if (secondContent && secondContent.hasClass('hidden-content')) {
                            _self.closeSecondaryContent();
                        }

                        for (var i = 0; i < modifiable.length; i++) {
                            _self.content.find('.main-row .button-radio[data-key="' + modifiable[i] + '"]').hide();
                            _self.content.find('[data-purpose="secondary-content-changer"][data-key="' + modifiable[i] + '"]').hide();
                            if (modifiable[i] != stone) {
                                _self.content.find('.main-row .button-radio[data-key="' + modifiable[i] + '"]').removeClass('selected');
                                _self.secondary_content.find('input[data-parent="' + modifiable[i] + '"]').each(function (key, item) {
                                    $(item).val(0);
                                    var id = $(item).attr('id');
                                    $(this).parents('.button-radio').removeClass('selected');
                                    if (id) {
                                        _self.secondary_content.find('.minus-arrow[data-target="'+id+'"]').addClass('disabled');
                                    }
                                });
                            }
                        }

                        _self.content.find('.main-row .button-radio[data-key="' + stone + '"]').show();
                        _self.content.find('[data-purpose="secondary-content-changer"][data-key="' + stone + '"]').show();
                    }
                });
            }

            if (this.isTwoStepCheck() && this.secondary_content) {
                this.content.find('.main-row input[type="radio"]').click(function () {
                    if ($(this).prop('checked')) {
                        var level = $(this).data('level');
                        if (level &&  level === 'main') {
                            _self.secondary_content.find('[data-purpose="secondary-content-changer"][data-key="' + $(this).val() + '"]').addClass('selected');
                            _self.openSecondaryContent($(this).val());
                        }
                    }
                });

                this.secondary_content.find('input[type="radio"]').change(function () {
                    _self.hideSwipeContent(400, _self.currentActiveSwipe);
                    var level = $(this).data('level');
                    var input = _self.content.find('input[type="radio"][data-level="main"][value="' + $(this).data('group') + '"]');

                    if (input.length > 0) {
                        input.prop('checked', true);
                    }

                    if (level && level == 'second') {
                        _self.parent.openNextSteps(_self.step);
                    }

                    if (_self.getKey() == 'stones') {
                        $(document).trigger('sirkoform.changestone', {
                            stone: $(this).data('group')
                        });
                    }

                    if (_self.getKey() == 'letter-type') {
                        var txtArea = _self.secondary_content.find('textarea');

                        if (txtArea.length > 0 &&  txtArea.val().trim() == '') {
                            _self.parent.hideFurtherSteps(_self.step);
                        }
                    }
                });

                if (this.getKey() == 'letter-type') {
                    this.secondary_content.find('.button-radio').click(function (e) {
                        var inputId = $(this).attr('for');
                        if (inputId) {
                            var input = $('#' + inputId);
                            if (input.length == 1) {
                                if (input.prop('checked')) {
                                    e.preventDefault();
                                    input.prop('checked', false);

                                    var txtArea = _self.secondary_content.find('textarea');

                                    if (txtArea.length > 0 &&  txtArea.val().trim() == '') {
                                        _self.parent.openNextSteps(_self.step);
                                    }
                                }
                            }
                        }
                    });
                }

                this.secondary_content.find('input[type="checkbox"]').change(function () {
                    _self.hideSwipeContent(400, _self.currentActiveSwipe);
                    var step = $(this).parents('.second-step');
                    if (step.length > 0) {
                        var key = step.data('parent');
                        var radio = _self.content.find('.main-row .button-radio[data-key="' + key + '"]');
                        if (radio.length > 0) {
                            var checked = step.find('input[type="checkbox"]:checked');
                            if (checked.length > 0 && !radio.hasClass('selected')) {
                                radio.addClass('selected');
                            } else if (checked.length == 0 && radio.hasClass('selected')) {
                                radio.removeClass('selected');
                            }
                        }
                    }
                });

                this.secondary_content.find('[data-purpose="secondary-content-changer"]').click(function (e) {
                    var key = $(this).data('key');
                    _self.secondary_content.find('[data-purpose="secondary-content-changer"]').removeClass('selected');
                    if (key == 'parent') {
                        _self.closeSecondaryContent();
                    } else {
                        $(this).addClass('selected');
                        _self.changeSecondaryContent(key);
                    }

                    e.preventDefault();
                });
            }

            if (this.getKey() == 'extend-type') {
                this.content.find('.main-row .button-radio').click(function () {
                    var key = $(this).data('key');
                    _self.secondary_content.find('[data-purpose="secondary-content-changer"][data-key="' + key + '"]').addClass('selected');
                    _self.openSecondaryContent(key);
                });

                this.secondary_content.find('[data-purpose="counter-btn"]').click(function(){
                    if ($(this).hasClass('disabled')) {
                        return;
                    }

                    var id = $(this).data('target');
                    var target = $('#'+id);
                    
                    if (target.length == 1) {
                        var oldValue = target.val();
                        var value = $(this).data('value');

                        var newValue = parseInt(oldValue)+parseInt(value);

                        if (newValue < 0) {
                            return false;
                        }

                        var minusBtn = $('.minus-arrow[data-target="'+id+'"]');
                        if (minusBtn.length > 0) {
                            if (newValue == 0 && !minusBtn.hasClass('disabled')) {
                                minusBtn.addClass('disabled');
                            } else if (newValue > 0 && minusBtn.hasClass('disabled')) {
                                minusBtn.removeClass('disabled');
                            }
                        }

                        target.val(newValue);

                        var mainItem = _self.content.find('.main-row .button-radio[data-key="'+target.data('parent')+'"]');
                        if (mainItem.length > 0) {
                            if (newValue > 0) {
                                $(this).parents('.button-radio').addClass('selected');

                                if (!mainItem.hasClass('selected')) {
                                    mainItem.addClass('selected');
                                }
                            } else {
                                $(this).parents('.button-radio').removeClass('selected');
                                
                                var hasSelected = false;
                                _self.secondary_content.find('input[data-parent="'+target.data('parent')+'"]').each(function(k, i){
                                    if (parseInt($(i).val()) > 0) {
                                        hasSelected = true;
                                    }
                                });

                                if (mainItem.hasClass('selected')) {
                                    mainItem.removeClass('selected');
                                }
                            }
                        }
                    }
                });
            }

            if (this.getKey() == 'letter-type') {
                this.secondary_content.find('textarea').on('keyup', function () {
                    var value = $(this).val();
                    var radio = _self.secondary_content.find('input[type="radio"]:checked');

                    if (value.trim() === '') {
                        if (radio.length == 0) {
                            _self.parent.openNextSteps(_self.step);
                        } else {
                            _self.parent.hideFurtherSteps(_self.step);
                        }
                    } else {
                        if (radio.length === 0) {
                            _self.parent.hideFurtherSteps(_self.step);
                        } else {
                            _self.parent.openNextSteps(_self.step);
                        }
                    }
                });
            }

            var resizeFn = debounce(function () {
                _self.setRowWidth();
            }, 100);

            window.addEventListener('resize', resizeFn);

            this.swipe_content.on('touchstart', function () {
                if (_self.currentActiveSwipe) {
                    _self.hideSwipeContent(0, _self.currentActiveSwipe);
                }
            });

            this.content.find('> .inner').on('scroll', function () {
                if (_self.currentActiveSwipe) {
                    _self.hideSwipeContent(0, _self.currentActiveSwipe);
                }
            });
        },
        openMainContent: function () {
            if (this.content.hasClass('hidden-content') ||  !this.isAvailable()) {
                this.setAvailability(true);
                this.content.removeClass('hidden-content');
                this.defaultHeight = this.content.height();

            }
        },
        openSecondaryContent: function (type) {
            if (!this.content.hasClass('active-secondary-content')) {
                this.content.addClass('active-secondary-content');
            }
            if (this.secondary_content.hasClass('hidden-content')) {
                this.secondary_content.removeClass('hidden-content');
            }

            this.changeSecondaryContent(type);
        },
        closeSecondaryContent: function () {
            var _self = this;
            this.secondary_content.addClass('hidden-content');
            this.content.removeClass('active-secondary-content');
            this.content.height(this.defaultHeight);
            setTimeout(function () {
                _self.content.removeAttr('style');
            }, 400);
        },
        changeSecondaryContent: function (type) {
            var item = this.secondary_content.find('.second-step[data-parent="' + type + '"]');

            this.secondary_content.find('.second-step').addClass('hidden-content');
            this.secondary_content.find('[data-purpose="secondary-lead"]').hide();
            if (item.length > 0) {
                var title = this.getSubtitle();
                if (title) {
                    title = title.replace('%NAME%', this.getText(type));
                } else {
                    title = this.getTitle();
                }

                this.secondary_content.find('[data-purpose="secondary-content-title"]').html(title);
                this.secondary_content.find('[data-purpose="secondary-lead"][data-key="' + type + '"]').show();
                item.removeClass('hidden-content');
                var newHeight = this.secondary_content.outerHeight();
                this.content.height(newHeight);
                this.setSecondaryRowWidth(type);
            }
        },
        setMinHeight: function () {
            if (this.secondary_content && this.isTwoStepCheck()) {
                this.content.removeAttr('style');

                var height = 0;
                this.secondary_content.find('.second-step').each(function () {
                    var itemHeight = $(this).outerHeight();
                    if (itemHeight > height) {
                        height = itemHeight;
                    }
                });

                height += this.secondary_content.outerHeight();
                height += this.secondary_content.find('.head-content').outerHeight();

                this.content.css('min-height', height);
            }
        },
        setRowWidth: function () {
            var _self = this;
            var items = null;
            this.content.find('.aksd-form-row').removeAttr('style');
            if ($(window).width() > 600) {
                return;
            }

            switch (this.key) {
                case 'tomb-type':
                    items = this.content.find('.radio-group');
                    break;
                case 'stones':
                    items = this.content.find('.stone-select-item');
                    break;
                default:
                    items = this.content.find('.main-row .button-radio');
                    break;
            }

            if (items !== null && items.length > 0) {
                var width = 0;
                $.each(items, function (index, item) {
                    if ($(item).is(':visible')) {
                        width += Math.ceil($(item).outerWidth());

                        if (_self.key == 'stones' || _self.getInputType() == 'checkbox' || _self.key == 'tomb-type') {
                            width += parseInt($(item).css('margin-right'));
                        }

                        if (_self.getInputType() == 'checkbox') {
                            width += parseInt($(item).css('margin-left'));
                        }
                    }
                });

                var row = this.content.find('.aksd-form-row.main-row');
                if (row.length > 0) {
                    row.removeClass('single-content');
                    width += 10;

                    if (width <= $(window).width()) {
                        row.addClass('single-content');
                    } else if (_self.key != 'letter-type') {
                        row.width(width);
                    }

                    if (width > $(window).width() && $(window).width() <= 600 &&  (!this.duringInit || this.step == 1) && _self.key != 'letter-type') {
                        this.showSwipeIcon('main');
                    }
                }
            }


        },
        setSecondaryRowWidth: function (type)  {
            var _self = this;
            var row = this.secondary_content.find('.second-step[data-parent="' + type + '"]');
            row.removeAttr('style');
            if ($(window).width() <= 600 && this.secondary_content) {
                var row = this.secondary_content.find('.second-step[data-parent="' + type + '"]');
                var stepWidth = 0;
                row.find('.button-radio').each(function (i, radio) {
                    stepWidth += Math.ceil($(radio).outerWidth());
                    if (_self.getInputType() == 'checkbox') {
                        stepWidth += parseInt($(radio).css('margin-left'));
                        stepWidth += parseInt($(radio).css('margin-right'));
                    }
                });

                // row.width(stepWidth);
                row.removeClass('single-content');
                stepWidth += 10;
                if (stepWidth <= $(window).width()) {
                    row.addClass('single-content');
                } else if (_self.key != 'letter-type') {
                    row.width(stepWidth);
                }

                if (this.isMobileView()) {
                    this.hideSwipeContent(0, this.currentActiveSwipe);

                    if (stepWidth > $(window).width() && $(window).width() <= 600 && _self.key != 'letter-type') {
                        this.showSwipeIcon('second-' + type);
                    }
                }
            }
        },
        setParams: function (params) {
            if (params.title) this.setTitle(params.title);
            if (params.subtitle) this.setSubtitle(params.subtitle);
            if (params.required) this.setRequired(params.required);
            if (params.values) this.setValues(params.values);
            if (params.key) this.setKey(params.key);
            if (params.input_name) this.setInputName(params.input_name);
            if (params.input_type) this.setInputType(params.input_type);
            if (params.two_step_check) this.setTwoStepCheck(params.two_step_check);
            if (params.step) this.setStep(params.step);
            if (params.available) this.setAvailability(params.available);
            if (params.lead) this.setLead(params.lead);
        },
        draw: function () {
            if (this.getKey() == 'letter-type') {
                this.secondary_content.append('<div class="clear"></div>');
            }

            this.parent.container.append(this.content);

            this.defaultHeight = this.content.height();
        },
        setHtml: function () {
            this.content = $('<div>', {
                'class': 'form-block ' + this.key + ' ' + (this.isAvailable() ? '' : 'hidden-content') + (this.step == this.parent.getCurrentStep() ? 'current-step' : ''),
                'data-key': this.key,
                'data-step': this.step
            });

            var lead = this.getLead();
            var title = $('<h2>', {
                html: this.getTitle(),
                class: 'primary-title ' + (lead !== null ? 'has-lead' : '')
            });

            this.content.append(title);

            if (lead !== null) {
                this.content.append('<div class="form-content-lead">' + lead + '</div>');
            }

            var inner = $('<div>', {
                class: 'inner'
            });

            inner.append(this.createFormInnerHtml());
            if (this.secondary_content) {
                inner.append(this.secondary_content);
            }

            this.swipe_content = $('<span>', {
                'class': 'swipe-outer',
                'html': svg('swipe-icon')
            });

            inner.append(this.swipe_content);
            this.content.append(inner);
        },
        createFormInnerHtml: function () {
            if (this.values || this.getInputType() == 'textarea') {
                switch (this.key) {
                    case 'tomb-type':
                        return this.createTombTypeContent();
                    case 'stones':
                        return this.createStoneTypeContent();
                    case 'letter-craving':
                        return this.createLetterCravingContent();
                    default:
                        return this.createDefaultFormContent();
                }
            }

            return null;
        },
        createLetterCravingContent: function () {
            var container = $('<div>', {
                'class': 'aksd-form-row letter-craving-row main-row'
            });

            var label = $('<label>', {
                for: 'letter-craving',
                html: this.getText('letter_type_input_label')
            });

            var input = $('<textarea>', {
                class: 'field',
                id: 'letter-craving',
                name: this.getInputName()
            });

            container.append(label);
            container.append(input);

            return container;
        },
        createStoneTypeContent: function () {
            var container = $('<div>', {
                'class': 'aksd-form-row stone-types-row main-row'
            });

            var valueKeys = Object.keys(this.values);
            if (valueKeys.length > 0) {
                for (var i = 0; i < valueKeys.length; i++) {
                    var key = valueKeys[i];
                    var value = this.values[key];

                    var content = $('<div>', {
                        class: 'stone-select-item',
                        'data-key': key
                    });

                    var input = $('<input>', {
                        type: 'radio',
                        name: 'offer_data[stone_family_parent]',
                        value: key,
                        id: 'stone-family-' + key,
                        'data-level': 'main',
                    });

                    var label = $('<label>', {
                        for: 'stone-family-' + key
                    });

                    if (typeof value.name !== 'undefined' || typeof value.label !== 'undefined') {
                        var name = typeof value.label !== 'undefined' ? this.getText(value.label) : value.name;

                        if (typeof value.image !== 'undefined' && value.image != '') {
                            label.append('<span class="image-content ' + this.getKey() + '"><img src="' + value.image + '" alt="' + name + '" /></span>');
                        } else {
                            label.append('<span class="image-content ' + this.getKey() + '"><span class="no-image"></span></span>');
                        }

                        label.append('<span class="name">' + name + '</span>');
                    }

                    var inputContent = $('<div>', {
                        class: 'input-content'
                    });

                    inputContent.append(input);
                    inputContent.append(label);

                    content.append(inputContent);                    
                    if (typeof value.arguments !== 'undefined') {
                        var proContent = $('<div>', {
                            class: 'stone-argument pro',
                            html: '<h4>' + svg('tick-icon') + this.getText('pro_title') + '</h4>' + this.getText(value.arguments.pro),
                        });

                        var versusContent = $('<div>', {
                            class: 'stone-argument versus',
                            html: '<h4>' + svg('versus-icon') + this.getText('versus_title') + '</h4>' + this.getText(value.arguments.versus)
                        });

                        content.append(proContent);
                        content.append(versusContent);
                    }

                    container.append(content);

                    this.createSecondStepItems(value.options, key);
                }
            }
            return container;
        },
        createDefaultFormContent: function () {
            var container = $('<div>', {
                'class': 'aksd-form-row main-row'
            });

            var valueKeys = Object.keys(this.values);
            if (valueKeys.length > 0) {
                for (var i = 0; i < valueKeys.length; i++) {
                    var key = valueKeys[i];
                    var value = this.values[key];
                    var id = this.getKey() + '-' + key;


                    if (!this.isTwoStepCheck() || (this.isTwoStepCheck() && this.getInputType() == 'radio')) {
                        var input = $('<input>', {
                            type: this.getInputType(),
                            id: id,
                            name: this.getInputName(true) + (this.getKey() == 'letter-type' ? 'parent' : ''),
                            value: key,
                            'data-level': 'main',
                        });
                        container.append(input);
                    }

                    var label = $('<label>', {
                        for: id,
                        class: 'button-radio ' + this.getKey() + '-radio',
                        'data-key': key
                    });

                    if (typeof value.icon !== 'undefined') {
                        label.append('<span class="icon-content ' + this.getKey() + ' ' + (this.getKey() == 'letter-painting' ? key : '') + '">' + svg(value.icon) + '</span>');
                    }

                    if (typeof value.name !== 'undefined' || typeof value.label !== 'undefined') {
                        var name = typeof value.label !== 'undefined' ? this.getText(value.label) : value.name;

                        if (typeof value.image !== 'undefined' && value.image != '') {
                            label.append('<span class="image-content ' + this.getKey() + '"><img src="' + value.image + '" alt="' + name + '" /></span>');
                        }

                        label.append('<span class="name">' + name + '</span>');
                    }


                    container.append(label);

                    if (this.isTwoStepCheck() && typeof value.options !== 'undefined') {
                        if (this.getKey() == 'extend-type') {
                            this.createExtendTypeSecondStep(value.options, key, value);
                        } else {
                            this.createSecondStepItems(value.options, key, value);
                        }
                    }
                }
            }

            return container;
        },
        createTombTypeContent: function () {
            var container = $('<div>', {
                'class': 'aksd-form-row main-row'
            });

            var valueKeys = Object.keys(this.values);
            if (valueKeys.length > 0) {
                for (var i = 0; i < valueKeys.length; i++) {
                    var key = valueKeys[i];
                    var value = this.values[key];
                    var selectOuter = $('<div>', {
                        class: 'radio-group ' + this.key + '-group ' + this.key + '-group-' + key,
                        'data-type': this.key
                    });

                    if (typeof value.icon !== 'undefined') {
                        selectOuter.append('<div class="icon-content">' + svg(value.icon) + '</div>');
                    }

                    if (typeof value.name !== 'undefined' || typeof value.label !== 'undefined') {
                        var name = typeof value.name !== 'undefined' ? value.name : this.getText(value.label);
                        var nameContent = $('<div>', {
                            class: 'name'
                        });
                        if (typeof value.options == 'undefined') {
                            var id = this.getKey() + '-' + key + '-input'
                            var input = $('<input>', {
                                type: 'radio',
                                name: this.getInputName(),
                                id: id,
                                value: key,
                                checked: key == this.parent.getSelectedTombType()
                            });
                            var label = $('<label>', {
                                html: name,
                                for: id
                            });

                            nameContent.append(input);
                            nameContent.append(label);
                        } else {
                            nameContent.append(name);
                        }

                        selectOuter.append(nameContent);
                    }

                    if (typeof value.options !== 'undefined' && !this.isTwoStepCheck()) {
                        var optKeys = Object.keys(value.options);
                        if (optKeys.length > 0) {
                            var optionContent = $('<div class="options">', {
                                class: 'options'
                            });

                            var id_prefix = this.getKey();
                            for (var j = 0; j < optKeys.length; j++) {
                                var optKey = optKeys[j];
                                var option = value.options[optKey];
                                var id = id_prefix + '-' + key + '-' + optKey;

                                if (typeof option === 'string') {
                                    var val = key + '-' + optKey;
                                    var input = $('<input>', {
                                        type: 'radio',
                                        name: this.getInputName(),
                                        id: id,
                                        value: val,
                                        'data-purpose': 'main-level-radio',
                                        checked: val == this.parent.getSelectedTombType()
                                    });
                                    var label = $('<label>', {
                                        'html': option,
                                        'for': id
                                    });

                                    selectOuter.append(input);
                                    selectOuter.append(label);

                                } else if (typeof option === 'object') {

                                }
                            }

                            selectOuter.append(optionContent);
                        }
                    }

                    if (typeof value.list_items === 'object') {
                        var listContent = $('<ul>');
                        for (var j = 0; j < value.list_items.length; j++) {
                            listContent.append('<li>' + this.getText(value.list_items[j]) + '</li>');
                        }

                        selectOuter.append(listContent);
                    }

                    container.append(selectOuter);
                }
            }

            return container;
        },
        createSecondaryContent: function () {
            if (!this.secondary_content) {
                this.secondary_content = $('<div>', {
                    class: 'aksd-form-row secondary-content hidden-content'
                });
                var inner = $('<div>', {
                    class: 'head-content'
                });

                inner.append('<h2 data-purpose="secondary-content-title">' + this.getSubtitle() + '</h2>');
                var changeContent = $('<div>', {
                    class: 'change-content'
                });

                changeContent.append('<a href="javascript:void(0)" data-purpose="secondary-content-changer" data-key="parent">' + this.getText('back') + '</a>');

                var keys = Object.keys(this.values);
                if (keys.length > 0) {
                    for (var i = 0; i < keys.length; i++) {
                        changeContent.append('<a href="javascript:void" data-purpose="secondary-content-changer" data-key="' + keys[i] + '">' + this.getText(keys[i]) + '</a>')
                    }
                }

                inner.append(changeContent);
                this.secondary_content.append(inner);
                var stepContent = $('<div>', {
                    class: 'step-content-outer inner',
                    'data-purpose': 'step-content-outer'
                });
                if (this.getKey() == 'letter-type') {
                    var label = $('<label>', {
                        for: 'letter-type-text',
                        html: this.getText('letter_type_input_label')
                    });

                    var txt = $('<textarea>', {
                        'class': 'field',
                        'name': this.getInputName() + '[text]',
                        'id': 'letter-type-text'
                    });

                    var inputContent = $('<div>', {
                        'class': 'textarea-content'
                    });

                    inputContent.append(label);
                    inputContent.append(txt);
                    stepContent.append(inputContent);
                }
                this.secondary_content.append(stepContent);
            }
        },
        createSecondStepItems: function (options, parent, data) {
            if (!this.secondary_content) {
                this.createSecondaryContent();
            }

            var content = $('<div>', {
                class: 'second-step hidden-content ' + this.getKey() + ' ' + parent + '-options',
                'data-parent': parent
            });

            if (typeof options === 'object') {
                var keys = Object.keys(options);
                if (keys.length > 0) {
                    var id_prefix = parent + '-';
                    if (this.getKey() == 'letter-type') {
                        var leftContent = $('<div>', {
                            class: 'left-content long-content'
                        });

                        var rightContent = $('<div>', {
                            class: 'right-content thin-content'
                        });
                    }

                    for (var i = 0; i < keys.length; i++) {
                        var key = keys[i];
                        var option = options[key];
                        
                        if (typeof option.inactive != 'undefined' && option.inactive) {
                            continue;
                        }

                        var id = this.getKey() + '-' + id_prefix + key;
                        var input = $('<input>', {
                            type: this.getInputType(),
                            name: this.getInputName() + (this.getKey() == 'letter-type' ? '[value]' : ''),
                            id: id,
                            value: id_prefix + key,
                            'data-level': 'second',
                            'data-group': parent,
                        });

                        var label = $('<label>', {
                            for: id,
                            class: 'button-radio ' + this.getKey()
                        });

                        if (typeof option.icon !== 'undefined') {
                            label.append('<span class="icon-content ' + this.getKey() + '">' + svg(option.icon) + '</span>');
                        }

                        if (typeof option.name !== 'undefined' || typeof option.label !== 'undefined') {
                            var name = typeof option.label !== 'undefined' ? this.getText(option.label) : option.name;

                            if (typeof option.image !== 'undefined') {
                                if (option.image == '') {
                                    label.append('<span class="image-content ' + this.getKey() + '"><span class="no-image"></span></span>')
                                } else {
                                    label.append('<span class="image-content ' + this.getKey() + '"><img src="' + option.image + '" alt="' + name + '" /></span>')
                                }
                            }

                            label.append('<span class="name">' + name + '</span>');
                        } else {
                            if (typeof option.image !== 'undefined') {
                                if (option.image == '') {
                                    label.append('<span class="image-content ' + this.getKey() + '"><span class="no-image"></span></span>')
                                } else {
                                    label.append('<span class="image-content ' + this.getKey() + '"><img src="' + option.image + '" alt="' + this.getKey() + '-' + key + '" /></span>')
                                }
                            }
                        }

                        if (this.getKey() == 'letter-type') {
                            if (key > 5) {
                                rightContent.append(input);
                                rightContent.append(label);
                            } else {
                                leftContent.append(input);
                                leftContent.append(label);
                            }
                        } else {
                            content.append(input);
                            content.append(label);
                        }
                    }

                    if (this.getKey() == 'letter-type') {
                        content.append(leftContent);
                        if (keys.length > 5) {
                            content.append(rightContent);
                        }
                        content.append('<div class="clear"></div>');
                    }
                }
            }

            var lead = data && typeof data.lead !== 'undefined' ? this.getText(data.lead) : null;
            if (lead !== null) {
                this.secondary_content.find('.head-content').append('<div class="form-content-lead" data-purpose="secondary-lead" data-key="' + parent + '">' + lead + '</div>')
            }
            this.secondary_content.find('[data-purpose="step-content-outer"]').append(content);
        },
        createExtendTypeSecondStep: function (options, parent, data) {
            if (!this.secondary_content) {
                this.createSecondaryContent();
            }

            var content = $('<div>', {
                class: 'second-step hidden-content ' + this.getKey() + ' ' + parent + '-options',
                'data-parent': parent
            });

            if (typeof options === 'object') {
                var keys = Object.keys(options);
                if (keys.length > 0) {
                    var id_prefix = parent + '-';
                    var inputNamePrefix = 'offer_data[extend_types]['+parent+']';

                    for (var i = 0; i < keys.length; i++) {
                        var key = keys[i];
                        var option = options[key];

                        var id = this.getKey() + '-' + id_prefix + key;
                        var label = $('<div>', {
                            class: 'button-radio extend-type-item ' + this.getKey(),
                            'data-level': 'second',
                            'data-group': parent
                        });

                        if (typeof option.name !== 'undefined' || typeof option.label !== 'undefined') {
                            var name = typeof option.label !== 'undefined' ? this.getText(option.label) : option.name;

                            if (option.image == '') {
                                label.append('<span class="image-content ' + this.getKey() + '"><span class="no-image"></span></span>')
                            } else {
                                label.append('<span class="image-content ' + this.getKey() + '"><img src="' + option.image + '" alt="' + name + '" /></span>')
                            }

                            label.append('<span class="name">' + name + '</span>');

                            var buttonRow = $('<div>', {
                                'class': 'input-row',
                                'html': '<span class="arrow-content minus-arrow disabled" data-target="' + id + '" data-purpose="counter-btn" data-value="-1"></span><span data-target="' + id + '" class="arrow-content plus-arrow" data-purpose="counter-btn" data-value="1"></span><input type="text" class="field counter-field" value="0" name="' + inputNamePrefix + '['+key+']" id="' + id + '" readonly data-parent="'+parent+'" />'
                            });

                            label.append(buttonRow);
                        }

                        content.append(label);
                    }
                }
            }

            var lead = data && typeof data.lead !== 'undefined' ? this.getText(data.lead) : null;
            if (lead !== null) {
                this.secondary_content.find('.head-content').append('<div class="form-content-lead" data-purpose="secondary-lead" data-key="' + parent + '">' + lead + '</div>')
            }

            this.secondary_content.find('[data-purpose="step-content-outer"]').append(content);
        },
        setKey: function (key) {
            this.key = key;
        },
        setInputName: function (input_name) {
            this.input_name = input_name;
        },
        setInputType: function (input_type) {
            this.input_type = input_type;
        },
        setTitle: function (title) {
            this.title = title;
        },
        setSubtitle: function (subtitle) {
            this.subtitle = subtitle;
        },
        setRequired: function (required) {
            this.required = required;
        },
        setTwoStepCheck: function (two_step) {
            this.two_step_check = two_step;
        },
        setValues: function (values) {
            this.values = values;
        },
        setStep: function (step) {
            this.step = step;
        },
        setAvailability: function (available) {
            this.available = available;
        },
        setLead: function (lead) {
            this.lead = lead;
        },
        getTitle: function () {
            return this.title ? this.getText(this.title) : null;
        },
        getLead: function () {
            return this.lead ? this.getText(this.lead) : null;
        },
        getSubtitle: function (name) {
            if (name) {
                var subtitle = this.subtitle;
                if (subtitle) {
                    subtitle = subtitle.replace('%NAME+', this.getText(key));
                    return subtitle;
                }
            }

            return this.subtitle ? this.getText(this.subtitle) : null;
        },
        getKey: function () {
            return this.key;
        },
        getInputName: function (parent) {
            var name = this.input_name ? this.input_name : this.getKey();

            var inputName = 'offer_data[' + name + (parent && this.isTwoStepCheck() ? '_parent' : '') + ']';

            return inputName + (this.getInputType() === 'checkbox' ? '[]' : '');
        },
        getInputType: function () {
            return this.input_type;
        },
        getText: function (key) {
            return this.parent ? this.parent.getText(key) : key;
        },
        isTwoStepCheck: function () {
            return this.two_step_check;
        },
        isRequired: function () {
            return this.required;
        },
        isAvailable: function () {
            return this.available;
        },
        isMobileView: function () {
            return $(window).width() <= 600;
        },
        showSwipeIcon: function (type) {
            var _self = this;
            if (!this.swipe_content.hasClass(type)) {
                this.swipe_content.addClass(type);
                this.swipe_content.show();
                this.currentActiveSwipe = type;

                setTimeout(function () {
                    if (!_self.swipe_content.hasClass('closed-' + type)) {
                        _self.hideSwipeContent(400, type);
                    }
                }, _self.parent.getHideTime());
            }
        },
        hideSwipeContent: function (time, type) {
            if (this.isMobileView()) {
                if (time == undefined) {
                    time = 400;
                }

                this.swipe_content.fadeOut(time);
                this.swipe_content.addClass('closed-' + type);
            }
        }
    }

    function TombForm(container, params) {
        try {
            if (!container) {
                throw 'Container';
            }

            this.container = container;

            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (e) {
            console.error(e);
        }
    }


    TombForm.prototype = {
        container: undefined,
        formItemObjs: undefined,
        formItems: undefined,
        formContent: undefined,
        sendedForm: false,
        selectedTombType: undefined,
        nextButton: undefined,
        prevButton: undefined,
        navigationRow: undefined,
        texts: {},
        currentStep: 1,
        availableStep: 1,
        maxSteps: 8,
        inputFields: ['name', 'phone', 'email', 'privacy', 'button'],
        formType: 'tomb',
        hideTime: 9000,
        notyf: undefined,
        init: function () {
            this.setFormObjs();
            this.notyf = new Notyf();

            if (this.formType == 'services') {
                this.createServicesFormContent();
            } else {
                this.createFormContent();
            }
            this.createNavigationRow();
            this.addDOMEvents();

            if (this.availableStep == this.maxSteps) {
                this.formContent.removeClass('hidden-content');
            }
        },
        addDOMEvents: function () {
            var _self = this;
            __createFormInputs();
            this.container.find('[data-purpose="form-send"]').click(function () {
                _self.sendForm();
            });

            this.prevButton.click(function () {
                if (_self.currentStep > 1) {
                    _self.goToPage(-1);
                }
            });

            this.nextButton.click(function () {
                var step = _self.formItemObjs[_self.currentStep];

                if ((!step.isRequired() || _self.currentStep < _self.availableStep) && _self.currentStep < _self.maxSteps) {
                    _self.goToPage(1);

                }
            });

            this.formContent.find('.phone-field').keypress(function (e) {
                var code = typeof e.which !== 'undefined' && e.which ? e.which : e.keyCode;
                var exeptions = [37, 39];
                var key = typeof e.key !== 'undefined' ? e.key : undefined;

                if (key && (key == "'" ||  key == "%")) {
                    return false;
                }

                if (exeptions.indexOf(code) >= 0) {
                    return true;
                }

                if (code > 31 && (code < 48 || code > 57)) {
                    return false;
                }

                return true;
            });

            if (this.availableStep <= this.currentStep) {
                this.nextButton.hide();
            }

            if (this.currentStep == 1) {
                this.prevButton.hide();
            }
        },
        setParams: function (params) {
            if (params.formItems) this.setFormItems(params.formItems);
            if (params.texts) this.setTexts(params.texts);
            if (params.availableStep) this.setAvailableStep(params.availableStep);
            if (params.selectedTombType) this.setSelectedTombType(params.selectedTombType);
            if (params.maxSteps) this.setMaxSteps(params.maxSteps);
            if (params.formType) this.setFormType(params.formType);
            if (params.inputFields) this.setInputFields(params.inputFields);
        },
        setFormItems: function (items) {
            this.formItems = items;
        },

        setFormObjs: function () {
            if (this.formItems) {
                var keys = Object.keys(this.formItems);
                if (keys.length > 0) {
                    this.formItemObjs = {};
                    var step = 1;
                    for (var i = 0; i < keys.length; i++) {
                        this.formItems[keys[i]].step = step;
                        this.formItems[keys[i]].available = parseInt(step) <= this.getAvailableStep();
                        this.formItemObjs[step] = new TombFormItem(keys[i], this.formItems[keys[i]], this);
                        step++;
                    }
                }
            }
        },
        createFormContent: function () {
            this.formContent = $('<div>', {
                class: 'form-block contact-section hidden-content',
                'data-key': 'form',
                'data-step': this.maxSteps
            });

            var leftContent = $('<div>', {
                class: 'input-content'
            });

            for (var i = 0; i < this.inputFields.length; i++) {
                var field = this.inputFields[i];
                var row = $('<div>', {
                    class: 'form-row ' + field + '-row'
                });

                var input = null;
                var input_label = null;
                if(field === 'privacy' ){

                    input = $('<input>', {
                        type: 'checkbox',
                        name: field,
                        class: 'field ' + (field == "phone" ? 'phone-field' : ''),                        
                        id: 'form-' + field,
                        'data-required': true,
                        value: 1                        
                    });

                    input_label = $('<label>', {
                        for: 'form-' + field,
                        html: this.getText('privacy_text')
                    });

                } else if (field !== 'button') {
                    row.addClass('mdl-js-textfield material-row');                    

                    input = $('<input>', {
                        type: 'text',
                        name: field,
                        class: 'field ' + (field == "phone" ? 'phone-field' : ''),
                        placeholder: this.getText(field + '_placeholder'),
                        id: 'form-' + field,
                        'data-required': true
                    });                   

                } else {
                    input = $('<button>', {
                        class: 'button',
                        type: 'button',
                        'data-purpose': "form-send",
                        'html': this.getText('form_button_text')
                    });
                }

                row.append(input);
                row.append(input_label);
                leftContent.append(row);
            }


            var inner = $('<div>', {
                class: 'inner-content'
            });

            inner.append(leftContent);
            inner.append(this.createFormInfoContent());
            inner.append('<div class="clear"></div>')

            this.formContent.append(inner);
            this.container.append(this.formContent);
        },
        createServicesFormContent: function () {
            this.formContent = $('<div>', {
                class: 'form-block contact-section hidden-content',
                'data-key': 'form',
                'data-step': this.maxSteps
            });

            var leftContent = $('<div>', {
                class: 'input-content'
            });

            var blocks = Object.keys(this.inputFields);
            if (blocks.length > 0) {
                for (var i = 0; i < blocks.length; i++) {
                    var block = this.inputFields[blocks[i]];
                    
                    var content = $('<div>', {
                        'class': 'contact-box contact-box-' + blocks[i]
                    });

                    for (var j = 0; j < block.length; j++) {
                        var item = block[j];
                        var row = $('<div>', {
                            class: 'form-row ' + item.name + '-row'
                        });
                        var field = item.name;
                        var input = null;
                        var input_label = null;
                        if (item.type == 'text') {
                            row.addClass('mdl-js-textfield material-row');
                            input = $('<input>', {
                                type: 'text',
                                name: field,
                                class: 'field ' + (field == "phone" || field == 'death_year' ? 'phone-field' : ''),
                                placeholder: this.getText(field + '_placeholder') + (item.required ? '*' : ''),
                                id: 'form-' + field,
                                'data-required': item.required
                            });

                            if (field == 'death_year') {
                                input.attr('maxlength', 4);
                            }
                        } else if (item.type == 'textarea') {
                            row.addClass('mdl-js-textfield material-row');
                            input = $('<textarea>', {
                                name: field,
                                class: 'field',
                                placeholder: this.getText(field + '_placeholder'),
                                id: 'form-' + field,
                                'data-required': item.required
                            })
                        } else if(item.type == 'checkbox' ){
        
                            input = $('<input>', {
                                type: 'checkbox',
                                name: field,
                                class: 'field ' + (field == "phone" ? 'phone-field' : ''),                        
                                id: 'form-' + field,
                                'data-required': true,
                                value: 1                        
                            });
        
                            input_label = $('<label>', {
                                for: 'form-' + field,
                                html: this.getText('privacy_text')
                            });
        
                        } else if (item.type == 'button') {
                            input = $('<button>', {
                                class: 'button',
                                type: 'button',
                                'data-purpose': "form-send",
                                'html': this.getText('form_button_text')
                            });
                        }

                        row.append(input);
                        row.append(input_label);

                        content.append(row);
                    }
                    if (blocks[i] == 3) {
                        content.append('<div class="info">' + this.getText('required_label') + '</div>');
                    }

                    leftContent.append(content);
                }
            }


            this.formContent.append(leftContent);
            this.formContent.append(this.createFormInfoContent());
            this.formContent.append('<div class="clear"></div>');
            this.container.append(this.formContent);
        },
        createFormInfoContent: function () {
            var rightContent = $('<div>', {
                class: 'info-content',
                html: this.getText('form_info_box_text')
            });

            rightContent.append(svg('info-letter'));
            return rightContent;
        },
        createNavigationRow: function ()  {
            this.navigationRow = $('<div>', {
                'class': 'navigation-row',
            });

            this.prevButton = $('<a>', {
                'href': 'javascript:void(0)',
                'class': 'button navigation-button prev-button',
                'data-purpose': 'navigation-button',
                'data-direction': 'prev',
                'html': svg('arrow-left') + this.getText('prev_step'),
            });

            this.nextButton = $('<a>', {
                'href': 'javascript:void(0)',
                'class': 'button navigation-button next-button',
                'data-purpose': 'navigation-button',
                'data-direction': 'next',
                'html': this.getText('next_step') + svg('arrow-right'),
            });

            var counterContent = $('<span>', {
                'class': 'counter-content',
                'html': '<span data-purpose="current-step">' + this.getCurrentStep() + '</span>/' + this.maxSteps
            });

            this.navigationRow.append(this.prevButton);
            this.navigationRow.append(this.nextButton);
            this.navigationRow.append(counterContent);
            this.container.append(this.navigationRow);
        },
        openNextSteps: function (step) {
            if (this.getAvailableStep() - parseInt(step) == 0) {
                var nextStep = parseInt(step) + 1;

                if (typeof this.formItemObjs[nextStep] !== 'undefined' &&  nextStep < this.maxSteps) {
                    this.setAvailableStep(nextStep);
                    this.formItemObjs[nextStep].openMainContent();
                    this.nextButton.fadeIn(300);
                    if (!this.formItemObjs[nextStep].isRequired()) {
                        this.openNextSteps(nextStep);
                    }
                } else if (nextStep == this.maxSteps) {
                    this.formContent.removeClass('hidden-content');
                }
            }
        },
        hideFurtherSteps: function (step) {
            this.setAvailableStep(step);
            for (var i = step + 1; i <= this.maxSteps; i++) {
                if (i == this.maxSteps) {
                    this.formContent.addClass('hidden-content');
                } else {
                    this.formItemObjs[i].setAvailability(false);
                    this.formItemObjs[i].content.addClass('hidden-content');
                }
            }

            this.nextButton.fadeOut(300);
        },
        goToPage: function (direction) {
            var nextStep = this.currentStep + direction;
            $(window).scrollTop(0);
            if (nextStep > 1) {
                this.prevButton.fadeIn(300);
            } else {
                this.prevButton.fadeOut(300);
            }

            if (this.currentStep == this.maxSteps) {
                this.formContent.removeClass('current-step');
                this.formItemObjs[nextStep].content.addClass('current-step');
                this.nextButton.fadeIn(300);
            } else {
                var current = typeof this.formItemObjs[this.currentStep] !== 'undefined' ? this.formItemObjs[this.currentStep] : this.formContent;
                var next = typeof this.formItemObjs[nextStep] !== 'undefined' ? this.formItemObjs[nextStep] : this.formContent;
                current.content.removeClass('current-step');

                if (nextStep < this.maxSteps && next.isRequired() &&  this.availableStep <= nextStep) {
                    this.nextButton.fadeOut(300);
                } else if (nextStep < this.maxSteps) {
                    this.nextButton.fadeIn(300);
                }

                if (nextStep == this.maxSteps) {
                    this.nextButton.fadeOut(300);
                    next.addClass('current-step');
                } else {
                    next.content.addClass('current-step');
                    next.setRowWidth();
                }
            }
            this.navigationRow.find('[data-purpose="current-step"]').html(nextStep);
            this.currentStep = nextStep;
        },
        setAvailableStep: function (step) {
            this.availableStep = parseInt(step);
        },
        setTexts: function (texts) {
            this.texts = $.extend({}, this.texts, texts);
        },
        setSelectedTombType: function (type) {
            this.selectedTombType = type;
        },
        setMaxSteps: function (steps) {
            this.maxSteps = steps;
        },
        setFormType: function (type) {
            this.formType = type;
        },
        setInputFields: function (fields) {
            this.inputFields = fields;
        },
        getText: function (key) {
            return typeof this.texts[key] !== 'undefined' ? this.texts[key] : key;
        },
        getCurrentStep: function () {
            return parseInt(this.currentStep);
        },
        getAvailableStep: function () {
            return parseInt(this.availableStep);
        },
        getSelectedTombType: function () {
            return this.selectedTombType;
        },
        sendForm: function () {
            if (!this.sendedForm) {
                var _self = this;
                this.sendedForm = true;

                $.ajax({
                    url: _self.container.attr('action'),
                    dataType: 'json',
                    type: 'post',
                    data: _self.container.serialize(),
                    beforeSend: function () {
                        _self.formContent.find('.has-error').removeClass('has-error');
                    },
                    success: function (response) {
                        if (typeof gtag !== 'undefined') {
                            var nameInput = $('#form-name');
                            if (nameInput.length == 1) {
                                var success = response.success;
                                var suffix = _self.formType == 'tomb' ? 'termék' : 'szolgáltatás';
                                gtag('event', 'Ajánlatkérés', {
                                    'event_category': 'Lead',
                                    'event_action': (success ? 'Sikeres' : 'Sikertelen') + ' ' + suffix + ' ajánlatkérés',
                                    'event_label': nameInput.val()
                                });
                            }
                        }
                        if (response.success) {
                            _self.showConfirmBlock();
                        } else {
                            if (typeof response.errors == 'object') {
                                var keys = Object.keys(response.errors);

                                if (keys.length > 0) {
                                    for (var i = 0; i < keys.length; i++) {
                                        var item = $('#form-' + keys[i]);

                                        if (item.length > 0) {
                                            item.parents('.form-row').addClass('has-error');
                                        }

                                        if (response.errors[keys[i]] !== '') {
                                            _self.notyf.alert(response.errors[keys[i]]);
                                        }
                                    }
                                }
                            }
                        }

                    },
                    complete: function () {
                        setTimeout(function () {
                            _self.sendedForm = false;
                        }, 1000);
                    }
                });
            }
        },
        showConfirmBlock: function () {
            this.formContent.html('');

            var content = $('<div>', {
                class: 'confirm-block'
            });

            content.append(svg('success-icon'));
            content.append('<h2>' + this.getText('success_form_title') + '</h2>');
            content.append('<div class="confirm-text">' + this.getText('success_form_text') + '</div>');

            this.formContent.append(content);
        },
        setHideTime: function (hide_time) {
            this.hideTime = hide_time;
        },
        getHideTime: function () {
            return this.hideTime;
        }
    };

    wnd.TombForm = TombForm;
})(window);
